import BlueInfoIcon from '../../Assets/Images/svg/blueInfoIcon.svg';
import RedInfoIcon from '../../Assets/Images/svg/redInfoIcon.svg';
import YellowInfoIcon from '../../Assets/Images/svg/yellowInfoIcon.svg';
import { ButtonColor, ToastStatus } from '../../Utils/constants';
import CustomButton from './CustomButton';

type Props = {
  text: string;
  description: string;
  status: ToastStatus;
  onButtonClick?: () => void;
  buttonText?: string;
};

const ToastMessage = ({ text, description, status, buttonText, onButtonClick }: Props) => {
  const getProps = () => {
    switch (status) {
      case ToastStatus.INFO:
        return {
          img: BlueInfoIcon,
          bgClass: 'bg-blue-3',
          textClass: 'text-blue-1',
        };
      case ToastStatus.ERROR:
        return {
          img: RedInfoIcon,
          bgClass: 'bg-pink-0',
          textClass: 'text-red-0',
        };
      case ToastStatus.WARNING:
        return {
          img: YellowInfoIcon,
          bgClass: 'bg-yellow-0',
          textClass: 'text-yellow-1',
        };
    }
  };

  return (
    <div
      className={`flex flex-row items-center justify-between border border-pink-0 rounded-[10px] px-[13px] py-[12px] w-full mb-2 ${
        getProps().bgClass
      }`}
    >
      <div className="flex gap-4 items-center">
        <img className="" src={getProps().img} alt="info icon" />
        <div className="flex flex-col items-start">
          <p className={`text-[15px] poppins-500 ${getProps().textClass}`}>{text}</p>
          <p className="text-gray-9 text-xs poppins-400">{description}</p>
        </div>
      </div>
      {onButtonClick && (
        <CustomButton colorClass={ButtonColor.DARK_BLUE} onClick={onButtonClick}>
          {buttonText}
        </CustomButton>
      )}
    </div>
  );
};

export default ToastMessage;
